import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import App from './app';
import initialState from './initialState'

import './index.css';

export default props => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        site {
          siteMetadata {
            name
            title
            date
          }
        }
        allSlide {
          edges {
            node {
              id
            }
          }
        }
      }
    `}
    render={data => (
      <App
        site={data.site}
        slidesLength={data.allSlide.edges.length}
        initialState={initialState}
        {...props}
      />
    )}
  />
);
