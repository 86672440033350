
import React, { Component }  from 'react';
import { Link } from 'gatsby';
import Player from '../components/player';

class Footer extends Component {
  render() {
    const { onProgress, keyframes, playing, onSetPlaying, playlists } = this.props;
    return (
      <footer>
        <div className="footer-root">
          <Player
            ref={player => 
              this.props.onSetPlayerRemote && 
              this.props.onSetPlayerRemote(player) 
            }
            playlists={playlists}
            progressInterval={100}
            onProgress={progressInMsec => onProgress(progressInMsec)}
            keyframes={keyframes}
            playing={playing}
            onSetPlaying={onSetPlaying}
          />
        </div>
        <div id="imprint-link">
          <Link to="/7">imprint</Link>
        </div>
        
      </footer>
    );
  }
}

export default Footer;
