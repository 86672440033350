import React, { Component } from 'react';

import ReactPlayer from 'react-player'

export default class Video extends Component {
  render() {
    return (
      <ReactPlayer
        {...this.props}
        playing
        loop
      />
    );
  }
}
