import React from 'react';

export default {
  when: 20210222,
  title: 'Hookup Launch',
  content: (
    <div style={{ height: '100%', marginTop: 20 }}>
      <h1 style={{ fontSize: 20 }}>
        First release of <a target="blank" href="https://hookup.to">hookup.to</a> 🎉
      </h1>
      <div style={{ width: "85%"}}>
        All alpha and in constant change: 
        the backend is online from time-to-time only, underwhelming UX on mobile, etc.
      </div>
      <div style={{ marginTop: 10 }}>
        Soon more on the project, I have been working on for almost three years now.
      </div>
    </div>
  )
}




