
export function leftKeyframeIndex(keyframes, progress, itemId) {
  const timeline = keyframes[itemId];
  if (!timeline) {
    return -1;
  }
  const oneTooFarIndex = timeline.findIndex(keyframe => keyframe.progress > progress);
  if (oneTooFarIndex === -1) {
    return timeline.length - 1;
  }
  return oneTooFarIndex - 1;
}

export function leftKeyframe(keyframes, progress, itemId) {
  const leftIndex = leftKeyframeIndex(keyframes, progress, itemId);
  return leftIndex !== -1 ? keyframes[itemId][leftIndex] : undefined;
}

export function rightKeyframeIndex(keyframes, progress, itemId) {
  const timeline = keyframes[itemId];
  if (!timeline) {
    return -1;
  }
  let rightIndex = timeline.length - 1;
  const idx = timeline.findIndex(keyframe => keyframe.progress > progress);
  if (idx !== -1) {
    rightIndex = idx;
  }
  return rightIndex;
}

export function rightKeyframe(keyframes, progress, itemId) {
  const rightIndex = rightKeyframeIndex(keyframes, progress, itemId);
  return rightIndex !== -1 ? keyframes[itemId][rightIndex] : undefined;
}

function getScaled(value, max) {
  if (value === undefined) {
    return undefined;
  }
  if (typeof value === 'string') {
    const percentage = Number(value.substr(0, value.length - 1)) / 100;
    return Math.round(percentage * max);
  }
  return value;
}

export function interpolateKeyframes(keyframes, progress, itemId, maxWidth, maxHeight) {
  const left = leftKeyframe(keyframes, progress, itemId);
  const right = rightKeyframe(keyframes, progress, itemId);

  if (!left || !right) {
    return left || right || {};
  }

  const { position: leftPosition, dimension: leftDimension, progress: leftProgress, props: leftProps } = left;
  const { x: leftX, y: leftY } = leftPosition || {};
  const { width: leftWidth, height: leftHeight } = leftDimension || {};

  const { position: rightPosition, dimension: rightDimension, progress: rightProgress, props: rightProps } = right;
  const { x: rightX, y: rightY } = rightPosition || {};
  const { width: rightWidth, height: rightHeight } = rightDimension || {};

  const tweenLength = rightProgress - leftProgress;
  const relativeTweenPosition = tweenLength > 0 ? (progress - leftProgress) / tweenLength : 0;
  const values = {
    x: (1 - relativeTweenPosition) * getScaled(leftX, maxWidth) + relativeTweenPosition * getScaled(rightX, maxWidth),
    y: (1 - relativeTweenPosition) * getScaled(leftY, maxHeight) + relativeTweenPosition * getScaled(rightY, maxHeight),
    width: ((1 - relativeTweenPosition) * getScaled(leftWidth, maxWidth) + relativeTweenPosition * getScaled(rightWidth, maxWidth)) || maxWidth,
    height: ((1 - relativeTweenPosition) * getScaled(leftHeight, maxHeight) + relativeTweenPosition * getScaled(rightHeight, maxHeight)) || maxHeight,
    hidden: !!left.hidden,
    keyframeProps: leftProps || {}
  };
  return values;
}

export function insertKeyframe(keyframes, keyframe, itemId) {
  const leftIndex = leftKeyframeIndex(keyframes, keyframe.progress, itemId);
  const leftKeyframe = keyframes[itemId][leftIndex];
  if (Math.abs(leftKeyframe.progress - keyframe.progress) < 0.001) {
    // close existing keyframe  - overwrite existing keyframe
    const insertedTimeline = Object.assign([], keyframes[itemId], { [leftIndex]: keyframe });
    return Object.assign({}, keyframes, { [itemId]: insertedTimeline });
  } else {
    // create a new keyframe
    if (leftIndex === 0 && keyframe.progress < leftKeyframe.progress) {
      const insertedTimeline = [keyframe].concat(keyframes[itemId]);
      return Object.assign({}, keyframes, { [itemId]: insertedTimeline });
    } else {
      const beforeInsertTimeline = keyframes[itemId].slice(0, leftIndex + 1);
      const afterInsertTimeline = keyframes[itemId].slice(leftIndex + 1);
      const insertedTimeline = beforeInsertTimeline.concat(keyframe).concat(afterInsertTimeline);
      return Object.assign({}, keyframes, { [itemId]: insertedTimeline });
    }
  }
}
