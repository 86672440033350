import React, { Component } from 'react';
import Draggable from 'react-draggable';

import Image from './scene-items/image';
import Video from './scene-items/video';
import Text from './scene-items/text';
import Html from './scene-items/html';

export default class DragItem extends Component {
  state = {
    dragged: false
  };

  render() {
    const { descriptor, position,  onDragged, maxWidth, maxHeight, dimension, keyframeProps } = this.props;
    return (
      <Draggable
        handle=".handle"
        position={position || descriptor.position}
        grid={[1, 1]}
        scale={1}
        //onStart={this.handleStart}
        onDrag={() => this.setState({ dragged: true })}
        onStop={(ev, node) =>
          this.state.dragged &&
          this.setState(
            { dragged: false },
            () => onDragged(ev, node)
          )
        }
      >
       <div
          className="handle"
          style={{ position: 'absolute' }}
        >
          { descriptor.type === 'image' && <Image {...descriptor} /> }
          { descriptor.type === 'video' && <Video {...descriptor} /> }
          { descriptor.type === 'text' && <Text {...descriptor} /> }
          { descriptor.type === 'html' && <Html {...descriptor} /> }
          { descriptor.type === 'component' && 
              (descriptor.markup && React.createElement(descriptor.markup, { ...descriptor, maxWidth, maxHeight, dimension, keyframeProps })) ||
              (descriptor.element && React.cloneElement(descriptor.element, { ...descriptor, maxWidth, maxHeight, dimension, keyframeProps }))
          }
        </div>
      </Draggable>
    );
  }
}
