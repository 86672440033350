import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Swipeable from 'react-swipeable';
import Header from '../components/header';
import Footer from '../components/footer';
import Transition from '../components/transition';
import Scene from '../components/Scene';
import Grid from '../components/canvas/grid';
import Gol from '../components/canvas/logic/gol';
import Water from '../components/canvas/logic/water';
import Emitter from '../components/canvas/logic/emitter';
import Bands from '../components/Bands';
import Blog from '../components/Blog';
import Imprint from '../components/Imprint';
import Menu from '../components/Menu';
import { insertKeyframe } from '../keyframes';

const menuEntries = ['do and enable', 'explore and experiment', 'zoom out'];
const hiddenEntries = {
  '3': 'do and enable',
  '4': 'blog party',
  '5': 'imprint'
};

export default class App extends Component {
  state = {
    currentScene: 0,
    playing: false,
    progressInMsec: 0,
    menuOpen: false,
    ...this.props.initialState,
  };

  navigate = ({ keyCode }) => {
    if (keyCode === 32) { // SPACE key toggles playing
      this.setState({ playing: !this.state.playing });
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.navigate);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.navigate);
  }

  addKeyframe = (itemId, keyframe) => {
    if (!this.state.playing) {
      // do not add keyframes while playing
      const { scenes } = this.state;
      const scene = scenes[this.state.currentScene];
      const keyframes = scene.keyframes;
      const newKeyframes = insertKeyframe(keyframes, keyframe, itemId);
      this.setState({ 
        scenes: Object.assign(
          [], 
          scenes, 
          { [this.state.currentScene]: { ...scene, keyframes: newKeyframes } }
        )
      });
    }
  }

  onNavigate = () => {
    this.setState({ menuOpen: false });
  }

  renderContent = (currentPageIndex, children) => {
    const scene = this.state.scenes[this.state.currentScene];
    switch(currentPageIndex) {
      case -1:
        return (
          <div id="slide">
            { children }
          </div>
        );
      case 0:
        return (
            <Scene
              durationInMsec={scene.durationInMsec}
              keyframes={scene.keyframes}
              onAddKeyframe={this.addKeyframe}
              createItems={this.state.createItems}
            />         
        );
      case 1:
        return (
          <Grid
            logic={[Gol, Water, Emitter]}
            running={this.state.playing}
            initialPopulation={this.state.initialPopulation}
          />
        );
      case 3:
        return (
          <Bands 
            onStartPlaylist = {
              (playlist, song) => this.player && this.player.setPlaylistAndSong(playlist, song)
            }
          />
        );
      case 4:
        return (
          <Blog />
        );
      case 5:
        return (
          <Imprint />
        );
      default:
        return (
          <div id="thescale">
            { children }
          </div>
        );
      }
  }
  
  render() {
    const { location, children, site } = this.props;
    const currentPageIndex = children.props.data && children.props.data.slide.index - 2;
  
    return (
      <div id="app">
        <Helmet
          title={`${site.siteMetadata.title} — ${site.siteMetadata.name}`}
        />
        <Header
          name={site.siteMetadata.name}
          title={site.siteMetadata.title}
          date={site.siteMetadata.date}
          onClickMenu={() => this.setState({ menuOpen: !this.state.menuOpen })}
          onNavigate={this.onNavigate}
          currentPageTitle={menuEntries[currentPageIndex] || hiddenEntries[currentPageIndex]}
        />
        {
          this.state.menuOpen && (
            <Menu
              onCloseMenu={this.closeMenu}
              onNavigate={this.onNavigate}
              menuEntries={menuEntries}
            />
          )
        }
        <Swipeable
          onSwipedLeft={() => {}}
          onSwipedRight={() => {}}
        >
          <Transition location={location}>
            <div style={{ marginTop: 50, marginBottom: 68 }}>
              { this.renderContent(currentPageIndex, children) }
            </div>
          </Transition>
        </Swipeable>
        <Footer
          playlists={this.state.playlists}
          onProgress={progressInMsec => this.setState({ progressInMsec })}
          keyframes={[]}
          playing={this.state.playing}
          onSetPlaying={playing => this.setState({ playing })}
          onSetPlayerRemote={remote => this.player = remote}
        />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
};
