import React, { Component } from 'react';

export default class Image extends Component {
  render() {
    return (
      <img
        ref={img => img && (img.ondragstart=() => false)}
        style={{ cursor: 'pointer' }}
        onContextMenu={e => e.preventDefault()}
        {...this.props}
      />
    );
  }
}
