import React, { Component } from 'react';
import { Link } from 'gatsby';

import playIcon from './play.png';
import lessThanIcon from './less-than.png';
import moreThanIcon from './more-than.png';

import window from './window';

const projects = [ 
  '#mix',
  '#cellardoor',
  '#bastuck.bombeck',
  '#draft',
  '#afoe',
  '#jeremysfate',
  '#minus',
  '#cascardas',
  '#frankajones',
];

class Headline extends Component {
  render() {
    return (
      <h2>{this.props.band} <span style={{ marginLeft: 5, fontSize: 13}}>{this.props.time}</span> </h2>
    );
  }
}

class Pick extends Component {
  render() {
    return (
      <div
        style={{
          marginTop: 15,
          marginBottom: 20,
          fontSize: 15,
        }}  
      >

        <div
          style={{
            cursor: 'pointer',
            margin: '5px 0px'
          }}
          onClick={this.props.onClick}
        >
          <div
            style={{
              width: 23,
              border: 'solid 1px black', 
              borderRadius: '50%' ,
              paddingLeft: 5,
              paddingTop: 1,
              float: 'left'
            }}
          >
            <img
              src={playIcon}
              height="13"
              width="13"
            />
          </div>
          <span style={{ marginLeft: 10 }}>{this.props.name}</span>
        </div>

        <span style={{ marginRight: 10 }}>
          { this.props.children }
        </span>
       
      </div>
    );
  }
}

export default class Bands extends Component {
  renderMix() {
    return (
      <div>
        <h2>Timeline</h2>
        <div>
          <ul
            style={{
              padding: 0,
            }}
          >
            <li>2012 <Link to="/5#cellardoor">Cellar Door</Link></li>
            <li>2011 - 2017 <Link to="/5#bastuck.bombeck">Bastuck.Bombeck</Link></li>
            <li>2011	<Link to="/5#draft">Draft</Link></li>
            <li>2006-2011	<Link to="/5#afoe">Afoe</Link></li>
            <li>2001-2005	<Link to="/5#jeremysfate">Jeremys Fate</Link></li>
            <li>1999-2002	<Link to="/5#minus">Minus</Link> </li>
            <li>1999-2004	<Link to="/5#cascardas">Cascardas</Link></li>
            <li>1997	<Link to="/5#frankajones">Franka Jones</Link> </li>
          </ul>
        </div>
      </div>  
    );
  }

  renderPlayButton(band) {
    return (
      <span
        style={{
          marginLeft: 4,
          cursor: 'pointer',
          fontSize: 15,
        }}
        onClick={() => this.props.onStartPlaylist(band) }
      >
         <img
            src={playIcon}
            height="13"
            width="13"
          /> PLAY
        </span>
    );
  }

  renderCellardoor() {
    return (
      <div
        style={{ 
          marginLeft: 30,
          width: '80%'
        }}
      >
        <Headline band="Cellar Door" time="2012" />
        <div>
          Hadn't written lyrics for a while, I guess I had something to say. 
          Rearranged two songs and a talented Nachtigall added vocals.
          The two songs are made available under <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="blank">following</a> creative commons license.
        </div>

        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('cellardoor', 'close')}
          name="Close"
        >
          Originally called 'Arroganz im Glück'. A <Link to="/5#draft">draft</Link> track. 
          An end merges into the next illusion of control, independence and purpose.
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('cellardoor', 'open')}
          name="Open"
        >
          Originally called 'Out of Love'. An <Link to="/5#afoe">afoe</Link> track, which always sounded like a <Link to="/5#draft">draft</Link> track in disguise.
          On the ambiguity of the term 'out of' in two verses.
        </Pick>
      </div>  
    );
  }

  renderAfoe() {
    return (
      <div>
        <Headline band="Afoe" time="2006-2011" />
        <div>
          During the Fraunhofer years analyzing more than making music or listening to it.
          { /* Working in the domain of music information retrieval, today an application of Machine Learning.*/ " "}
          Afoe became an idea too vague and abstract to actually start writing songs for.
        </div>
        <p>
          Then, changing sides, gearing up at Native, getting back into the flow.
          Songs are available under <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="blank">following</a> creative commons license.
        </p>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('afoe', 'antique')}
          name="Antique"
        >
          Around that track I learned how session and arrangement view work together: epiphanic
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('afoe', 'trails of blood and wine in the snow')}
          name="Trails of blood and wine in the snow"
        >
          Passing <a href="https://www.lido-berlin.de/">Lido</a> every morning on my way to work, which got one 
          of my favorite venues for concerts.
          The whole area resonated when post-rock peaked, and you literally could go every night 
          and being astonished by the city.
        </Pick>
      </div>  
    );
  }

  renderJeremysfate() {
    return (
      <div>
        <Headline band="Jeremys Fate" time="2001-2005" />
        <div>
          Started as a five man combo in 2000. Lot of changes and even more learnings - "we are family"
        </div>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('jeremysfate', 'Lies are me, Nelly')}
          name="Lies are me, Nelly"
        >
          For some reason we chose one of our least catchy songs for <a href="https://www.youtube.com/watch?v=UzHPZwNqnEc" target="blank">this</a>. Messages are more important than the medium, right? Even when there is none.
        </Pick>
        
        <Pick
          onClick={() => this.props.onStartPlaylist('jeremysfate', 'Have to')}
          name="Have to"
        >
          Recording from Team17 Studio in Mainz Budenheim
        </Pick>
        
        <Pick
          onClick={() => this.props.onStartPlaylist('jeremysfate', 'Take me')}
          name="Take me"
        >
          Our first demo. When I learned writing songs is not the same as recording them. Still the song is good. We spent a whole summer mixing our first longplayer. And, we could have added more reverb, or at least some cowbell.
        </Pick>
      </div>  
    );
  }

  renderDraft() {
    return (
      <div>
        <Headline band="Draft" time="2011" />
        <div>
          Goal: Limitation. Write around five songs using same Live set. By this avoid distractions during writing like browsing sounds or adding effects.
          There is one outlier but ended up in having eight instead of five songs - was kind of a tradeoff.
        </div>
        <p>
          Songs are rough versions, hardly mixed and arranged, no vocals but weirdly named instead.
          Songs are made available under <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="blank">following</a> creative common s license.
        </p>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('draft', 'arroganz im glueck')}
          name="Arroganz im Glück"
        >
          Always admired the chord progression of <a href="https://www.youtube.com/watch?v=Qq4j1LtCdww" target="blank">Poison</a>. The modulations are formidable. Much less in here, but that's where the idea came from. Picked this as the first for <Link to="/5#cellardoor">Cellar Door</Link>.
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('draft', 'ivan der grosse')}
          name="Ivan der Große"
        >
          The day he woke up and met his new neighbor Gulliver
        </Pick>
      </div>  
    );
  }

  renderCascardas() {
    return (
      <div>
        <Headline band="Cascardas" time="1999 - 2004" />
        Cascardas was supposed to be the name of the <Link to="/5#minus">Minus</Link> album that was called 'Broken Tears' in the end.
        Explorations in combining electronic and guitar sounds on my home pc with minimum equipment and sound-engineering skills. 
        Mostly experimental and lo-fi.
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('cascardas', 'die to see')}
          name="Die to see"
        >
          With Falk I wrote two songs during summer '99. We could only finish only one track, most of the time laying <a href="https://de.wikipedia.org/wiki/Hardt_(Wuppertal)" target="blank">here</a>
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('cascardas', 'we missed in time and place')}
          name="We missed in time and space"
        >
          First laptop, fruity loops, a mistuned acoustic guitar I traded for my first 50DM Les Paul clone, sitting on the floor of the bedroom, 
          no headphones, no metronome, build-in mic and the CPU fan kicking in as a supporting voice.
        </Pick>
        
      </div> 
    );
  }

  renderBastuckBombeck() {
    return (
      <div>
        <Headline band="Bastuck.Bombeck" time="2011 - 2017" />
        <div>
          A collaboration of two, empowered by Astra and like a schachtel pralinen: who knows what's next?
          We enjoy making music from Schweineorgel to Jump-and-run soundtracks. And guitars hitting the 12!
        </div>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('bastuck.bombeck', 'the day i jumped mario in the neck')}
          name="The day I jumped Mario in the neck"
        >
          A very early one, where we agreed the world needs more cowbell.
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('bastuck.bombeck', 'vokuhero')}
          name="Vokuhero"
        >
          Much later, more layers, still a love for cowbells and the wisdom that a real hero wears <a href="https://www.google.com/search?q=vokuhila&tbm=isch" target="blank">Vokuhila</a>!
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('bastuck.bombeck', 'adult bobby car rallye')}
          name="Adult bobby car rallye"
        >
          Or, as <a href="https://www.youtube.com/watch?v=gZWRPhdVFlY" target="blank">he</a> said: "As an adult, if I want a cookie, I'll have a cookie".
        </Pick>
      </div>  
    );
  }

  renderMinus() {
    return (
      <div>
        <Headline band="Minus" time="1999-2002" />
        <div>
          Four man combo, songs about melancholy, love and hope. Recorded in 1999 with a simple Yamaha 8 track analog recorder in our drummer's basement.
          Our album was called 'Broken Tears'. 
        </div>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('minus', 'joining hands')}
          name="Joining hands"
        >
          I had a small notepad where I wrote down sentences and stuff I thought of. At some point I handed this to our singer.   
          Later, I recognized them in our lyrics. Like this dream.
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('minus', 'broken tears')}
          name="Broken tears"
        >
          DADACD - it's the tuning I made-up and used for half of the songs on the record. Of course, I could afford only one Les Paul. So, every second song I had to re-tune my guitar during a gig. The audience was "amazed".
        </Pick>
        <Pick
          onClick={() => this.props.onStartPlaylist('minus', 'key to my heart')}
          name="Key to my heart"
        >
          That first time when I saw she actually likes this song we just recorded.
          {/*Still remember a tear on the cheek of my friend when I showed her this song we just recorded.*/}
        </Pick>
      </div>
    );
  }

  renderFrankaJones() {
    return (
      <div>
        <Headline band="Franka Jones" time="1997" />
        <div>
          Everything comes from a 486 running a very early fruity version.
          Afterwards, a friend made up a story about a girl from London, Franka Jones, who wrote these tracks. He then gave me an address for the demo. I got excited.
        </div>
        <h4 style={{ marginBottom: 0 }}>Picks</h4>
        <Pick
          onClick={() => this.props.onStartPlaylist('frankajones', 'track1')}
          name="track1"
        >
         
        </Pick>
      </div>
    );
  }

  renderContent() {
    switch(window.location.hash) {
      case '#cellardoor': 
        return this.renderCellardoor();
      case '#afoe': 
        return this.renderAfoe();
      case '#jeremysfate': 
        return this.renderJeremysfate();
      case '#draft': 
        return this.renderDraft();
      case '#cascardas': 
        return this.renderCascardas();
      case '#bastuck.bombeck': 
        return this.renderBastuckBombeck();
      case '#minus': 
        return this.renderMinus();
      case '#frankajones':
        return this.renderFrankaJones();
      case '#mix': 
      default:
        return this.renderMix();
    }
  }

   render() {
    const idx = projects.indexOf(window.location.hash);
    const nextIdx = (idx !== -1 && idx < (projects.length - 1)) && idx + 1;
    return (
      <div id="bands">
        <h1>Music I was involved in</h1>
        {
          idx > 0 && (
            <div
              style={{
                cursor: 'pointer',
                marginTop: 50,
                marginRight: 50
              }}
              onClick={this.onNext}
            >
              <Link to={`/5${projects[idx - 1]}`}>
                <img src={lessThanIcon} />
              </Link>
            </div>
          )
        }
        <div className="project">
          <div style={{ width: '80%' }}>
            { this.renderContent() }
          </div>
          {
            idx === 0 && 
              <Link to="/2" style={{ textDecoration: 'none' }}><div style={{ fontSize: 16 }}>◀ back</div></Link>
          }
        </div>
        {
          nextIdx !== false && (
            <div
              style={{
                cursor: 'pointer',
                marginTop: 50
              }}
              onClick={this.onNext}
            >
              <Link to={`/5${projects[nextIdx]}`}>
                <img src={moreThanIcon} />
              </Link>
            </div>
          )
        }
      </div>
    );
  }
}
