import React, { Component } from 'react';

export default class Html extends Component {
  render() {
    const { value, style } = this.props;
    return (
      <div style={style}>
        {value}
      </div>
    );
  }
}
