import React, { Component } from 'react';

import QRCode from 'qrcode.react';
import uuid from 'uuid';

import window from '../window';
import { hookupGridBoard } from '../../Config';

function componentToHex(c) {
  const w = Math.ceil(c * 255);
  const hex = w.toString(16);
  return hex.length === 1 ? `${hex}` : hex;
}

function rgbaToHex(r, g, b, a) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}${componentToHex(a)}`;
}

export function createAtom(x, y) {
  return { x, y, type: 'atom' };
}

export function createParticle(x, y, ax, ay) {
  return { x, y, ax, ay, type: 'particle', age: 0 };
}

export function createReflector(x, y, direction) {
  return { x, y, type: 'reflector', direction };
}

function createConnectionUrl() {
  const origin = window.location.origin;
  const companionId = uuid();
  return { 
    connectionCodeDestination: `${origin}/companion/${companionId}`,
    companionId,
  };
}

const headerHeight = 58;
const footerHeight = 68;

export default class Grid extends Component {
  logic = [];
  firstRenderPassed = false;

  state = {
    mouseDown: false,
    population: this.props.initialPopulation,
    cellDimemsions: { width: 10, height: 10 },
    showConnectionCode: false,
    ...createConnectionUrl(),
    boardCenterX: undefined,
    boardCenterY: undefined
  }

  componentDidMount() {
    Grid.firstRenderPassed = false;
    window.addEventListener('resize', this.detectDimensions.bind(this));
    if (hookupGridBoard) {
      this.evtSource = new EventSource(`${hookupGridBoard}/stream`);
      this.evtSource.addEventListener('data', this.onEvent) 
    }
    this.detectDimensions();
  }

  componentWillUnmount() {
    if (this.evtSource) {
      this.evtSource.removeEventListener('data', this.onEvent);
      this.evtSource = null;
    }
    
    clearTimeout(this.interval);
    this.interval = undefined;
  }

  onEvent = ({ data }) => {
    const { numCellsX, numCellsY } = this.getNumCells();
    const input = JSON.parse(data);

    const scaleX = Number(input.gamma) / 90 * 0.5 + 0.5; // to range [0:1]
    const boardCenterX = Math.round(scaleX * numCellsX);

    const scaleY = Math.min(Number(input.beta) / 70 * 0.5 + 0.5, 1); // to range [0:1]
    const boardCenterY = Math.round(scaleY * numCellsY);

    this.setState({ boardCenterX, boardCenterY }); 
  }

  detectDimensions = () => {    
    const width = window.innerWidth;
    const height = window.innerHeight - footerHeight - headerHeight;
    this.setState(
      { width: width, height: height },
      this.renderGrid.bind(this)
    );
  }

  renderRaster = (ctx, numCellsX, numCellsY) => {
    ctx.strokeStyle = '#00000002';
    for (let y=0; y<numCellsY; ++y) {
      ctx.beginPath();
      ctx.moveTo(0, this.state.cellDimemsions.height * y);
      ctx.lineTo(this.state.width, this.state.cellDimemsions.height * y);
      ctx.stroke();
    }
    for (let x=0; x<numCellsX; ++x) {
      ctx.beginPath();
      ctx.moveTo(this.state.cellDimemsions.width * x, 0);
      ctx.lineTo(this.state.cellDimemsions.height * x, this.state.height);
      ctx.stroke();
    }
  }

  renderGrid = () => {
    const ctx = this.canvas.getContext('2d');
    const { numCellsX, numCellsY } = this.getNumCells();
    ctx.clearRect(0, 0, this.state.width, this.state.height);

    this.renderRaster(ctx, numCellsX, numCellsY);
    for (let y=0; y<numCellsY; ++y) {
      for (let x=0; x<numCellsX; ++x) {
        ctx.fillStyle = this.getCellColor(x, y);
        ctx.fillRect(
          this.state.cellDimemsions.width * x,
          this.state.cellDimemsions.height * y,
          this.state.cellDimemsions.width - 1,
          this.state.cellDimemsions.height - 1
        );
      }
    }
      
    if (!this.props.running) {
      ctx.fillStyle = '#ce9136bb';
      ctx.font = '60px Arial';
      ctx.fillText(
        'PRESS PLAY ON TAPE',
        Math.floor(numCellsX * 0.27) * this.state.cellDimemsions.width, 
        Math.floor(numCellsY * 0.5) * this.state.cellDimemsions.height,  
        Math.floor(numCellsX * 0.5) * this.state.cellDimemsions.width
      );
    }

    if (false) {
      const covered = this.scanArea(ctx);      
      if (covered) {
        this.firstRenderPassed = true;
        const particles = covered.map(a => {
          const rollDice = () => Math.round(Math.random() * 2 - 1) * 2
          const ax = rollDice();
          const ay = rollDice();
          return createParticle(a.x, a.y, ax, (ax === 0 && ay === 0) ? ay + 1 : ay)
        });
        setTimeout(
          () => this.setState({ population: this.state.population.concat(particles) }), 
          0
        );
      }
    }
  }

  scanArea = ctx => {
    const coveredCells = [];
    
    const { numCellsX, numCellsY } = this.getNumCells();
    if (!numCellsX || !numCellsY) {
      return;
    }
    
    const pixelMeanGray = img => {
      let mean = 0;
      const n = img.data.length / 4;
      for (let i=0; i<n; ++i) {
        const r = img.data[i * 4];
        const g = img.data[i * 4 + 1];
        const b = img.data[i * 4 + 2];
        // const a = img.data[i * 4 + 3];
        mean += (r + g + b) / 3;
      }
      return mean / n;
    };
   
    for (let y=0; y<numCellsY; ++y) {
      for (let x=0; x<numCellsX; ++x) {
        const imageData = ctx.getImageData(
          this.state.cellDimemsions.width * x,
          this.state.cellDimemsions.height * y,
          this.state.cellDimemsions.width - 1,
          this.state.cellDimemsions.height - 1  
        );
        const pixel = pixelMeanGray(imageData);
        if (pixel < 180) {
          coveredCells.push({x, y});
        }
      }
    }
    return coveredCells;
  }

  getCellAt = (x, y) => {
    return this.state.population.find(cell => cell.x === x && cell.y === y);
  }

  getCellColor = (x, y) => {
    const cell = this.getCellAt(x, y);
    if (!cell) {
      return 'white';
    }
    switch(cell.type) {
      case 'atom':
        return 'green';
      case 'particle':
        return 'purple';
      case 'reflector': 
        return 'red';
    }
    return 'black';
  }

  isCellOfType = (x, y, type) => {
    const cell = this.getCellAt(x, y);
    if (type) {
      return !!cell && cell.type === type;
    }
    return !!cell;
  }

  insertAtom = (ev, deleteIfExists) => {
    const x = Math.floor(ev.clientX / this.state.cellDimemsions.width);
    const y = Math.floor((ev.clientY - footerHeight) / this.state.cellDimemsions.height);
    const cell = this.getCellAt(x, y);
    if (!cell) {
      const atom = createAtom(x, y);
      this.setState({ population: this.state.population.concat(atom) });
    } else if (deleteIfExists && cell.type === 'atom') {
      const atomRemoved = this.state.population.filter(c => !(c.type === 'atom' && c.x === x && c.y === y));
      this.setState({ population: atomRemoved });
    }
  }

  getNumCells = () => {
    const numCellsX = Math.ceil(this.state.width / this.state.cellDimemsions.width);
    const numCellsY = Math.ceil(this.state.height / this.state.cellDimemsions.height);
    return { numCellsX,  numCellsY }; 
  }

  iterate = () => {
    if (this.logic) {
      this.logic.forEach(l => l.iterate());
    }

    if (this.state.boardCenterX !== undefined) {
      const { numCellsY, numCellsX } = this.getNumCells(); 
      const boardWidth = 5;
      const halfBoardLen = Math.floor(boardWidth / 2);
      const bottomBoard = Array.apply(null, { length: boardWidth }).map((_, i) => 
        createReflector(this.state.boardCenterX + i - halfBoardLen, numCellsY - 4, 'horizontal')
      );
      const topBoard = Array.apply(null, { length: boardWidth }).map((_, i) => 
        createReflector(this.state.boardCenterX + i - halfBoardLen, 4, 'horizontal')
      );
      const leftBoard = Array.apply(null, { length: boardWidth }).map((_, i) => 
        createReflector(1, this.state.boardCenterY + i - halfBoardLen, 'vertical')
      );
      const rightBoard = Array.apply(null, { length: boardWidth }).map((_, i) => 
        createReflector(numCellsX - 4, this.state.boardCenterY + i - halfBoardLen, 'vertical')
      );
      this.updatePopulation(
        [ ...bottomBoard, ...topBoard, ...leftBoard, ...rightBoard ], 
        'reflector'
      );
    }
  }

  updatePopulation = (p, type) => {
    const leftPopulation = this.state.population.filter(x => x.type !== type);
    this.setState({
      population: leftPopulation.concat(p)
    });
  }

  renderButton = (label, onClickHandler) => {
    const buttonStyle = {
      width: 25,
      height: 25,
      fontSize: 15,
      cursor: 'pointer',
      userSelect: 'none', 
      marginBottom: 10,
      borderRadius: 5,
      backgroundColor: 'lightgray',
      textAlign: 'center'
    };
    return (
      <div
        style={{ 
          display: 'flex', 
          justifyContent: 'center',
        }}
      >
        <div 
          style={buttonStyle}
          onClick={onClickHandler}
        >
          { label }
        </div>
      </div>
    );
  }

  renderInteractivePanel = () => {
    return (
      <div
        style={{ 
          position: 'absolute',
          width: 70,
          bottom: 100,
          right: 0,
          backgroundColor: '#FFFFFF2A'
        }}
      >
      <div
          style={{
            fontSize: '0.32em',
            textAlign: 'center',
            marginBottom: 10
          }}
        >
          { this.state.population.length }
        </div>
        { 
          hookupGridBoard && 
            this.renderButton('📱', () => {
              this.setState(
                { showConnectionCode: true },
                () => setTimeout(
                  () => this.setState({ showConnectionCode: false }),
                  4000
                )
              )
            })
        }
        { 
          this.renderButton('+', () => this.setState({
            cellDimemsions: {
              width: this.state.cellDimemsions.width + 2,
              height: this.state.cellDimemsions.height + 2
            }})
          )
        }
        
        { 
          this.renderButton('-', () => this.setState({
            cellDimemsions: {
              width: Math.max(5, this.state.cellDimemsions.width - 2),
              height: Math.max(5, this.state.cellDimemsions.height - 2)
            }})
          )
        }
      </div>
    );
  }

  renderConnectionCode = () => {
    const qrCodeSize = 128;
    return (
      <QRCode
        className={this.state.showConnectionCode ? 'opaque' : 'transparent' }
        style={{
          position: 'absolute',
          top: this.state.height && (this.state.height / 2 - qrCodeSize / 2),
          left: this.state.width && (this.state.width / 2 - qrCodeSize / 2),
        }} 
        size={qrCodeSize}
        value={this.state.connectionCodeDestination}
      />
    );
  }

  render() {
    if (this.canvas) {
      this.renderGrid();
    }

    const { running, logic } = this.props;
    const isRunning = running && !this.state.mouseDown;
    if (isRunning && !this.interval) {
      this.interval = setInterval(this.iterate.bind(this), 100);
    } else if (!isRunning && this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }

    const width = this.state.width || 100;
    const height = this.state.height || 100;
    const gridSize = {
      width: width / this.state.cellDimemsions.width,
      height: height / this.state.cellDimemsions.height
    }
    
    return (
      <div>
        <canvas
          ref={canvas => {
            if (!this.canvas) {
              this.canvas = canvas;
            }
          }}
          onMouseDown={ev => {
            this.insertAtom(ev, true);
            this.setState({ mouseDown: true });
          }}
          onMouseUp={() => this.setState({ mouseDown: false })}
          onMouseMove={ev => this.state.mouseDown && this.insertAtom(ev, false)}
          width={width}
          height={height}
        />
        {
          logic && logic.map((L, idx) => React.createElement(
            L,
            {
              key: idx,
              ref: l => this.logic[idx] = l,
              isCellOfType: this.isCellOfType.bind(this),
              getCellAt: this.getCellAt.bind(this),
              population: type => type ?
                this.state.population.filter(p => p.type === type) :
                this.state.population,
              updatePopulation: this.updatePopulation,
              createAtom,
              createParticle,
              gridSize
            }
          ))
        }
        { this.renderInteractivePanel() }
        { this.renderConnectionCode() }
      </div>
    );
  }
}
