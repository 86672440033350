import React, { Component } from 'react';

export default class Text extends Component {
  render() {
    const { value } = this.props;
    return (
      <div
        style={{
          color: 'white'
        }}
      >
        {value}
      </div>
    );
  }
}
