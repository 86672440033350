import React, { Component } from 'react';

import window from './window';
import BlogTimeline from './BlogTimeline';
import Launch from './blog/0-Launch';
//import Disclaimer from './blog/1-Disclaimer';
//import Test from './blog/2-Test';
import HkpLaunch from './blog/1-HkpLaunch';

const entries = [
  Launch,
  HkpLaunch,
  // Disclaimer,
  // Test
];

export default class Blog extends Component {
  getLatest = () => {
    return entries[entries.length - 1];
  }

  findEntry = date => {
    if (!date) {
      return this.getLatest();
    }
    const idx = entries.findIndex(item => item.when > date);
    const entry = (idx === -1) ? 
      this.getLatest() : 
      entries[Math.max(0, idx - 1)];
    return entry;
  }

  render() {
    const date = window.location.hash;
    const entry = this.findEntry(date.substr(1));
    return (
      <div
        id="blog"
      >
        <BlogTimeline 
          current={entry.when}
          entries={entries}
        />
        <div id="blog-panel">
        <h1>{entry.title}</h1>
        <div className="blog-date">{ entry.when }</div>
        <div className="blog-content">{ entry.content }</div>
      </div>
      </div>
    );   
  }
}
