import React, { Component } from 'react';

import Progressor from '../helpers/Progressor';
import Draggable from './draggable';
import { interpolateKeyframes } from '../keyframes';

import window from './window';

let globalProgressInMsec = 0;

export default class Scene extends Component {
  state = {
    maxWidth: 0,
    maxHeight: 0,
    progressInMsec: 0
  };

  componentDidMount() {
    window.addEventListener('resize', this.detectDimensions.bind(this));
    setTimeout(this.detectDimensions.bind(this), 0);
  }

  componentWillUnmount() {
    globalProgressInMsec = this.state.progressInMsec;
  }

  detectDimensions = () => {
    this.setState(
      { 
        maxWidth: window.innerWidth, 
        maxHeight: window.innerHeight - 150 // TODO: const is approx. header plus footer height
      }
    );
  }

  setProgress(progressInMsec) {
    if (this.progressor) {
      this.progressor.setPassedTime(progressInMsec);
    }
  }

  render() {
    const { keyframes, createItems, durationInMsec } = this.props;
    const items = createItems(this);
    const { progressInMsec } = this.state;
    const maxWidth = this.state.maxWidth;
    const maxHeight = this.state.maxHeight;
    return (
      <div>
        <Progressor
          ref={progressor => this.progressor = progressor}
          startProgress={globalProgressInMsec}
          onProgress={progress => {
            this.setState({ progressInMsec: progress.playedSeconds * 1000 })
          }}
          progressInterval={10}
          durationInMsec={durationInMsec}
        /> 
        {
          items.map(item => {
            const values = interpolateKeyframes(keyframes, progressInMsec, item.id, maxWidth, maxHeight);
            return values.hidden ? false : (
              <Draggable
                key={item.id}
                descriptor={item}
                position={values}
                onDragged={() => {}}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                dimension={values}
                keyframeProps={values.keyframeProps}
              />
            )
          })
        }
      </div>
    );
  }
}
