import React, { Component } from 'react';
import { Link } from 'gatsby';

import pauseIcon from './pause.png';
import playIcon from './play.png';
import fastForwardIcon from './fast-forward.png';
import linkIcon from './link.png';

export default class Player extends Component {
  state = {
    showArtists: false,
    showSongs: false
  };

  renderPlayButton() {
    const {
      isPlaying,
      onSetPlaying,
    } = this.props;
    return (
      <div onClick={() => onSetPlaying(!isPlaying)}>
        { isPlaying ?
            <img
              src={pauseIcon}
              height="25"
              width="25"
            /> :
            <img
              src={playIcon}
              height="25"
              width="25"
            />
        }
      </div>
    );
  }

  renderNextButton() {
    const { onNext } = this.props;
    return (
      <div
        style={{
          marginTop: 2,
          marginLeft: 5,
        }}
        onClick={onNext}
      >
        <img
          src={fastForwardIcon}
          height="31"
          width="31"
        />
      </div>
    );
  }

  renderCurrentSong() {
    const { currentSong } = this.props;
    return (
      <div
        style={{
          fontSize: '50%',
          marginTop: 10,
          marginLeft: 10
        }}
      >
        <span
          ref={artistSpan => this.artistSpan = artistSpan }
          style={{cursor: 'pointer'}}
          onClick={this.onArtist}
        >
          {currentSong.artist}
        </span> -
        <span
          ref={songSpan => this.songSpan = songSpan }
          onClick={this.onSong}
          style={{cursor: 'pointer'}}
        >
          {` ${currentSong.title}`}
        </span>
      </div>
    );
  }

  renderArtists() {
    const { playlists, onPlaylist } = this.props;
    if (!this.state.showArtists) {
      return false;
    }
    return (
      <div
        className="playlist-overlay"
        style={{ left: this.artistSpan && this.artistSpan.getBoundingClientRect().x - 10 }}
      >
        {
          playlists.map((playlist, index) => (
            <div key={playlist.name}>
              <a
                className="playlist-item"
                onClick={() => this.setState({ showArtists: false }, () => onPlaylist(index))}
              >
                { playlist.name }
              </a>
              <Link
                style={{
                  marginLeft: 15,
                }}
                to={`/5#${playlist.name}`}
                onClick={() => this.setState({ showArtists: false })}
              >
                <img src={linkIcon} height="15" />
              </Link>
            </div>
          ))
        }
      </div>
    );
  }

  renderSongs() {
    const { playlists, currentPlaylistIdx, onSong } = this.props;
    if (!this.state.showSongs) {
      return false;
    }
    return (
      <div
        className="playlist-overlay"
        style={{
          left: this.songSpan && this.songSpan.getBoundingClientRect().x - 10,
        }}
      >
        {
          playlists[currentPlaylistIdx].tracks.map((track, index) => (
            <div key={track.title}>
              <a
                className="playlist-item"
                onClick={() =>this.setState({ showSongs: false }, () => onSong(index))}
              >
                { track.title }
              </a>
            </div>
          ))
        }
      </div>
    );
  }

  onArtist = () => {
    this.setState({
      showArtists: !this.state.showArtists,
      showSongs: false
    });

  }
  onSong = () => {
    this.setState({
      showSongs: !this.state.showSongs,
      showArtists: false
    });

  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        { this.renderPlayButton() }
        { this.renderNextButton() }
        { this.renderCurrentSong() }
        { this.renderArtists() }
        { this.renderSongs() }
      </div>
    );
  }
}
