import React, { Component } from 'react';

export default class Emitter extends Component {
  time = 0;
  state = {
    sources: [{ x: 1, y: 1, ax: 1, ay: 1, f: 6 } ],
    delta: 0
  };

  isSourceReady = (source, updatedTime) => {
    const s = this.state.delta / 90 * 0.5 + 0.5;

    const f = 6 - Math.round(source.f * s);
    return (updatedTime % f) === 0;
  }

  iterate = () => {
    const updatedTime = this.time++;
    const { population, updatePopulation, createParticle } = this.props;
    const particles = population('particle');
    const newborns = this.state.sources.reduce(
      (all, source) => this.isSourceReady(source, updatedTime) ?
        all.concat(createParticle(source.x, source.y, source.ax, source.ay)) :
        all,
      []
    );
    updatePopulation(particles.concat(newborns), 'particle');
  }

  render() {
    return (
      <div />
    );
  }
}
