import React, { Component } from 'react';

import { Link } from 'gatsby';

export default class BlogTimeline extends Component {
  static months = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  renderMonth(m) {
    return BlogTimeline.months[Number(m)];
  }

  renderDate(dateAsNum) {
    const d = `${dateAsNum}`;
    const year = d.substr(0, 4);
    const month = d.substr(4, 2);
    const day = d.substr(6, 2);
    return (
      <Link 
        to={`/6#${d}`}
        style={{
          textDecoration: 'none'
        }}
      >
        {this.renderMonth(month)}. {day}, {year}
      </Link>
    );
  }

  render() {
    const { entries, current } = this.props;
    const entryStyle = {
      fontSize: 12,
      marginLeft: 15,
    };
    const currentEntryStyle = {
      ...entryStyle,
      fontSize: entryStyle.fontSize * 1.5,
    }
    return (
      <div
        style={{
          width: '100%',
          paddingRight: 20,
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: '40%',
            textAlign: 'right',
            marginLeft: 'auto',
            marginRight: 0,
          }}
          >
            {
              entries && entries.map(entry => (
                <span
                  key={entry.when}
                  style={entry.when == current ? currentEntryStyle : entryStyle}
                >{this.renderDate(entry.when)}</span>
              ))
            }
            <div 
              style={{
                borderBottom: 'solid .3px lightgray',
              }}
              >
              </div>
        </div>
      </div>
    );
  }
}