import React from 'react';
import { Link } from 'gatsby';

const Header = ({ name, title, date, onClickMenu, onNavigate, currentPageTitle }) => (
  <header>
    <div>
      <Link
        to="/1"
        onClick={() => onNavigate()}
      >
        <span>
          {name}
        </span>
      </Link>
      {` — `}
      <span
        className="menuLink"
        onClick={onClickMenu}
      >
        {currentPageTitle || 'pick a thing'}
      </span>
    </div>
    <time>
      <Link to="/6">
        {date}    
      </Link>
    </time>
  </header>
);

export default Header;
