import React, { Component } from 'react';
import ReactPlayer from 'react-player'

import Controls from './controls';
import Progress  from './progress'

export default class Player extends Component {
  state = {
    positionInPercent: 0,
    positionInMsec: 0,
    currentSongIdx: 0,
    currentPlaylistIdx: 0,
    durationInMsec: 0
  };

  setPlaylistAndSong(name, song) {
    const { playlists } = this.props;
    const playlistIdx = playlists.findIndex(playlist => playlist.name === name);
    if (playlistIdx !== -1) {
      this.setState({ currentPlaylistIdx: playlistIdx });
      
      const songIdx = playlists[playlistIdx].tracks.findIndex(cur => cur.title === song)
      if (songIdx !== -1) {
        this.setState({ currentSongIdx: songIdx });
      }
      this.props.onSetPlaying(true); // start playback
    } else {
      console.log('Could not find', name, 'in', playlists)
    }
  }

  doSeek = () => {
    this.player.seekTo(this.state.positionInPercent);
  }

  onProgress = progress => {
    const { onProgress } = this.props;
    const positionInMsec = progress.playedSeconds * 1000;
    if (progress.played >= 1) {
      this.nextTrack();
    }
    this.setState(
      { positionInPercent: progress.played, positionInMsec },
      () => onProgress(positionInMsec)
    );
  }

  nextTrack = () => {
    const { playlists } = this.props;
    const playlist = playlists[this.state.currentPlaylistIdx];
    const tracks = playlist.tracks;
    const incrementedSongIdx = this.state.currentSongIdx + 1;
    const nextSongIdx = (incrementedSongIdx < tracks.length) ? incrementedSongIdx : 0;
    this.setState({ currentSongIdx: nextSongIdx });
  }

  render() {
    const { playlists, progressInterval, keyframes, playing, onSetPlaying } = this.props;
    const playlist = playlists[this.state.currentPlaylistIdx];
    const tracks = playlist.tracks;
    return (
      <div>
        <div>
          <Progress
            position={this.state.positionInPercent}
            onPositionChange={newPos => this.setState({ positionInPercent: newPos }, this.doSeek)}
            keyframes={keyframes}
            duration={this.state.durationInMsec}
          />
        </div>
        <div>
          <Controls
            isPlaying={playing}
            onSetPlaying={onSetPlaying}
            currentSong={tracks[this.state.currentSongIdx]}
            onNext={() => this.nextTrack()}
            currentPlaylistIdx={this.state.currentPlaylistIdx}
            playlists={playlists}
            onPlaylist={index => this.setState({ currentPlaylistIdx: index, currentSongIdx: 0 } )}
            onSong={index => this.setState({ currentSongIdx: index })}
          />
        </div>
        <div>
          <ReactPlayer
            ref={player => {
              if (!this.player) {
                this.player = player;
                this.player.getInternalPlayer().load()
                this.player.onError = err => {
                  console.error(err);
                }
                this.player.onReady = () => {
                  //console.log('is ready')
                }
              }
            }}
            url={tracks[this.state.currentSongIdx].mp3}
            playing={playing}
            progressInterval={progressInterval}
            onProgress={this.onProgress}
            onDuration={durationInSec => this.setState({ durationInMsec: durationInSec * 1000 })}
            loop={false}
          />
        </div>
      </div>
    );
  }
}
