import React, { Component } from 'react';

export default class Progressor extends Component {
  timer = undefined;
  passedTime = this.props.startProgress;
  timeAtLastCall = 0;

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }

  setPassedTime(passedTimeInMsec) {
    this.passedTime = passedTimeInMsec;
  }

  render() {
    const { onProgress, progressInterval, durationInMsec } = this.props;
    if (!this.timer) {
      this.timer = setInterval(
        () => {
          const now = Date.now();
          this.passedTime += this.timeAtLastCall ? now - this.timeAtLastCall : progressInterval;
          this.timeAtLastCall = now;
          if (this.passedTime > durationInMsec) {
            onProgress({ played: 1, playedSeconds: durationInMsec / 1000 });
            clearInterval(this.timer);
            this.timer = undefined;
          } else {
            onProgress({ played: this.passedTime / durationInMsec, playedSeconds: this.passedTime / 1000 });
          }
        },
        progressInterval
      );
    }
    return false;
  }
}
