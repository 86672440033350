import React from 'react';

import { createAtom, createParticle } from '../components/canvas/grid';

import Ideas from '../scenes/Ideas';

import {
  playlist,
  afoe,
  jf,
  draft,
  cascardas,
  minus,
  bb,
  cellar,
  franka
} from './resources';

const initialState = {
  playlists: [
    { name: 'mix', tracks: playlist },
    { name: 'cellardoor', tracks: cellar },
    { name: 'bastuck.bombeck', tracks: bb },
    { name: 'draft', tracks: draft },
    { name: 'afoe', tracks: afoe },
    { name: 'jeremysfate', tracks: jf },
    { name: 'minus', tracks: minus },
    { name: 'cascardas', tracks: cascardas },
    { name: 'frankajones', tracks: franka },
  ],
  createItems: scene => [
    ...Ideas.createItems(scene)
  ].map((item, idx) => ({ ...item, id: item.id || `${idx + 1}` })),
  scenes: [
    ...Ideas.scenes
  ],
  initialPopulation: [
    createAtom(2, 1),
    createAtom(3, 1),
    createAtom(4, 1),
  ]
};

export default initialState;
