import React from 'react';

export default {
  when: 20190617,
  title: 'Launch',
  content: (
    <div>
      <div>
        Hitting the first checkpoint! 
        
        Disabled most features for having a first version.

        <div>They will come at a later.</div>
      </div>
    </div>
  )
};