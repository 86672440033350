export const playlist =  [
  {title:"close", artist:"cellardoor", mp3:"https://cbastuck.de/mp3/draft/vocals/draft6_beta_3_mixdown_revised_vocals_3.mp3"},
  {title:"speak now or forever hold your peace", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-speaknoworforeverholdyourpeace.mp3"},
  {title:"camping in the basement", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-campinginthebasement.mp3"},
  {title:"the day i jumped mario in the neck", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-thedayijumpedmariointheneck.mp3"},
  {title:"arroganz im glueck", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-arroganzimglueck.mp3"},
  {title:"soziale dissonanzen", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-sozialedissonanzen.mp3"},
  {title:"behind a trap door", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-behindatrapdoor.mp3"},
  {title:"antique", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-antique.mp3"},
  {title:"she dumped me on the dancefloor", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-shedumpedmeonthedancefloor.mp3"},
  {title:"lies are me, nelly", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Lies are me Nelly.mp3"},
  {title:"have to", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Have to.mp3"},
  {title:"something", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Something.mp3"},
  {title:"we missed in time and place", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-wemissedintimeandplace.mp3"},
  {title:"die to see", artist:"cascardas feat. falk lenn", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-feat.falk-dietosee.mp3"},
  {title:"your way", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-yourway.mp3"},
  {title:"joining hands", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - joining hands.mp3"},
  {title:"circling point", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - circling point.mp3"},
  {title:"broken tears", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - broken tears.mp3"},
  {title:"track1", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track1.MP3"},
  {title:"track2", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track2.MP3"},
]

export const afoe = [
  {title:"trails of blood and wine in the snow", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-trailsofbloodandwineinthesnow.mp3"},
  {title:"taking a hair of the dog", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-takingahairofthegog.mp3"},
  {title:"she dumped me on the dancefloor", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-shedumpedmeonthedancefloor.mp3"},
  {title:"antique", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-antique.mp3"},
  {title:"behind a trap door", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-behindatrapdoor.mp3"},
  {title:"if i dont feel it, does it actually exist?", artist:"afoe and andre estermann", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-ifidontfeelidoesitactuallyexist.mp3"},
  {title:"get tricked by a squirrel", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-gettrickedbyasquirrel.mp3"},
  {title:"no substance", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-nosubstance.mp3"},
  {title:"out of love", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-outoflove.mp3"},
  {title:"fluently", artist:"afoe", mp3:"https://www.cbastuck.de/mp3/afoe/afoe-fluently.mp3"},
];

export const draft = [
  {title:"mein gott walter", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-meingottwalter.mp3"},
  {title:"ivan der grosse", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-ivandergrosse.mp3"},
  {title:"bulgur und schnitzelbaguette", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-bulgurundschnitzelbaguette.mp3"},
  {title:"scheiss auf easy", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-scheissaufeasy.mp3"},
  {title:"soziale dissonanzen", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-sozialedissonanzen.mp3"},
  {title:"arroganz im glueck", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-arroganzimglueck.mp3"},
  {title:"proaktiv ins wochenende", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-proaktivinswochenende.mp3"},
  {title:"bonus", artist:"draft", mp3:"https://www.cbastuck.de/mp3/draft/draft-bonus.mp3"},
];

export const cascardas = [
  {title:"we missed in time and place", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-wemissedintimeandplace.mp3"},
  //{title:"jar of patterns", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-jarofpatterns.mp3"},
  {title:"once my mind was clear", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-oncemymindwasclear.mp3"},
  {title:"your way", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-yourway.mp3"},
  //{title:"you scare your child", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-youscareyourchild.mp3"},
  {title:"dissonant behaviour", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-dissonantbehaviour.mp3"},
  {title:"follow the link", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-followthelink.mp3"},
  {title:"to like your pain", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-tolikeyourpain.mp3"},
  {title:"die to see", artist:"cascardas feat. falk lenn", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-feat.falk-dietosee.mp3"},
  {title:"falling snow", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-fallingsnow.mp3"},
  {title:"grew up and left", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-grewupandleft.mp3"},
  //{title:"playing with scissors", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-playingwithscissors.mp3"},
  {title:"fallen free", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-fallenfree.mp3"},
  //{title:"incomplete", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-incomplete.mp3"},
  {title:"monotony", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-monotony.mp3"},
  {title:"toward a shore", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-towardsashore.mp3"},
  {title:"sprout", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-sprout.mp3"},
  {title:"ashamed", artist:"cascardas feat. falk lenn", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-feat.falk-ashamed.mp3"},
  {title:"feel the same", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-feelthesame.mp3"},
  // {title:"homecoming", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-homecoming.mp3"},
  {title:"classicaly refused", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-classicalyrefused.mp3"},
  {title:"no think", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-nothink.mp3"},
  //{title:"simple as that", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-simpleasthat.mp3"},
  {title:"remain", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-remain.mp3"},
  {title:"solitary wounds", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-solitarywounds.mp3"},
  // {title:"not an enemy", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-notanenemy.mp3"},
  {title:"happy ugly", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-happyugly.mp3"},
  {title:"too", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-too.mp3"},
  // {title:"mad to rock", artist:"cascardas", mp3:"https://cbastuck.de/mp3/cascardas/cascardas-madtorock.mp3"},
];

export const franka = [
  {title:"track1", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track1.MP3"},
  {title:"track2", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track2.MP3"},
  {title:"track3", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track3.MP3"},
  {title:"track4", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track4.MP3"},
  {title:"track5", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track5.MP3"},
  {title:"track6", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track6.MP3"},
  {title:"track7", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track7.MP3"},
  {title:"track8", artist:"frankajones", mp3:"https://www.cbastuck.de/mp3/frankajones/Franka Jones - track8.MP3"},
];

export const jf = [
  {title:"Lies are me, Nelly", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Lies are me Nelly.mp3"},
  {title:"Have to", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Have to.mp3"},
  {title:"Something", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Something.mp3"},
  {title:"Fake", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Fake.mp3"},
  {title:"You dont know", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - You dont know.mp3"},
  {title:"Take me", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Take me.mp3"},
  {title:"Shadows of my past", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Shadows of my past.mp3"},
  {title:"I am god", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - I am god.mp3"},
  {title:"Try to make it right", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Try to make it right.mp3"},
  {title:"I feel", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - I feel.mp3"},
  {title:"In your eyes", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - In your eyes.mp3"},
  {title:"With you", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - With you.mp3"},
  {title:"Two sides of a man", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Two sides of a man.mp3"},
  {title:"Wasted time", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Wasted time.mp3"},
  {title:"Die in my hands", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Die in my hands.mp3"},
  {title:"Britney", artist:"jeremysfate", mp3:"https://www.cbastuck.de/mp3/jeremysfate/Jeremys Fate - Britney.mp3"}
];

export const bb = [
  {title:"last march", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-lastmarch.mp3"},
  {title:"hans die wurst", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-hansdiewurst.mp3"},
  {title:"pluto safari", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-plutosafari.mp3"},
  {title:"camping in the basement (2013)", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-campinginthebasement2013.mp3"},
  {title:"vokuhero", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-vokuhero.mp3"},
  {title:"cold chicks and hot turkey", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-coldchicksandhotturkey.mp3"},
  {title:"pommes schranke", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-pommesschranke.mp3"},
  {title:"locked myself and swallowed the key (vocals)", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-lockedmyselfandswallowedthekey(vocals).mp3"},
  {title:"adult bobby car rallye", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-adultbobbycarrallye.mp3"},
  {title:"at the last line of the last page of the last book on the shelf", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-atthelastline.mp3"},
  {title:"locked myself and swallowed the key", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-lockedmyselfandswallowedthekey.mp3"},
  {title:"wearing a fatsuit on the first date", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-wearafatsuitonthefirstdate.mp3"},
  {title:"circles around the eyes", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-circlesaroundtheeyes.mp3"},
  {title:"speak now or forever hold your peace", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-speaknoworforeverholdyourpeace.mp3"},
  {title:"the day i jumped mario in the neck", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-thedayijumpedmariointheneck.mp3"},
  {title:"twisting the ankle seems popular these days", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-twistingtheankleseemspopularthesedays.mp3"},
  {title:"camping in the basement", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-campinginthebasement.mp3"},
  {title:"if i only had a mouse organ", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-ifionlyhadamouseorgan.mp3"},
  {title:"the rhythm is ndabdedabdebadah (desert)", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-therhythmisndabdedabdebadah(desert).mp3"},
  {title:"the rhythm is ndabdedabdebadah (orig)", artist:"bastuck.bombeck.koehn", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/bastuck.bombeck-therhythmisndabdedabdebadah(origin).mp3"},
  {title:"vier", artist:"bastuck.bombeck", mp3:"https://www.cbastuck.de/mp3/bastuckbombeck/vier.mp3"},
];

export const minus = [
  {title:"joining hands", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - joining hands.mp3"},
  {title:"need to be", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - need to be.mp3"},
  {title:"broken tears", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - broken tears.mp3"},
  {title:"everthing", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - everthing.mp3"},
  {title:"circling point", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - circling point.mp3"},
  {title:"dont mind", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - dont mind.mp3"},
  {title:"bring me back to see", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - bring me back to see.mp3"},
  {title:"key to my heart", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - key to my heart.mp3"},
  {title:"my life", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - my life.mp3"},
  {title:"the other side", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - the other side.mp3"},
  {title:"they want them to die", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - they want them to die.mp3"},
  {title:"you", artist:"minus", mp3:"https://www.cbastuck.de/mp3/minus/minus - you.mp3"}
];

export const cellar = [
  {title:"close", artist:"cellardoor", mp3:"https://cbastuck.de/mp3/draft/vocals/draft6_beta_3_mixdown_revised_vocals_3.mp3"},
  {title:"open", artist:"cellardoor", mp3:"https://cbastuck.de/mp3/draft/vocals/sool_vocals_12_final_1.mp3"},
];
