import React, { Component } from 'react';

const neighborDeltas = [
  { dx: -1, dy: -1 }, { dx: 0, dy: -1 }, { dx: 1, dy: -1 },
  { dx: -1, dy:  0 },                    { dx: 1, dy:  0 },
  { dx: -1, dy:  1 }, { dx: 0, dy:  1 }, { dx: 1, dy:  1 }];

export default class Grid extends Component {
  cache = {};

  getCachedNumberOfNeighbors = (x, y) => {
    return this.cache[`${x}.${y}`];
  }

  setCachedNumberOfNeighbors = (x, y, n) => {
    this.cache[`${x}.${y}`] = n;
  }

  clearCache = () => {
    this.cache = {};
  }

  computeNumNeighbors = (x, y) => {
    const cached = this.getCachedNumberOfNeighbors(x, y);
    if (cached) {
      return cached;
    }
    const { isCellOfType } = this.props;
    const n = neighborDeltas.reduce(
      (num, delta) => {
        const neighbor = { x: x + delta.dx, y: y + delta.dy };
        return num + Number(isCellOfType(neighbor.x, neighbor.y));
      },
      0
    );
    this.setCachedNumberOfNeighbors(x, y, n);
    return n;
  }

  iterate = () => {
    this.clearCache();
    const { population, updatePopulation, createAtom } = this.props;
    const pop = population('atom');
    const populationNumNeighbors = pop.map(cell => this.computeNumNeighbors(cell.x, cell.y));

    const survivors = pop.filter(
      (_, cellIdx) => populationNumNeighbors[cellIdx] > 1 && populationNumNeighbors[cellIdx] < 4
    );

    const visited = survivors.map(s => `${s.x}.${s.y}`);
    const newborns = pop
      .map(cell => neighborDeltas.map(delta => {
        const neighbor = createAtom(cell.x + delta.dx, cell.y + delta.dy);
        const neighborId = `${neighbor.x}.${neighbor.y}`;
        if (visited.indexOf(neighborId) !== -1) {
          return null;
        }
        visited.push(neighborId);
        return this.computeNumNeighbors(neighbor.x, neighbor.y) === 3 ?
          neighbor :
          null
      }))
      .reduce((all, list) => all.concat(list.filter(newborn => !!newborn)), []);
    updatePopulation(survivors.concat(newborns), 'atom'); // replace all atoms with current population
  }

  render() {
    return (
      <div />
    );
  }
}
