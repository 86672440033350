import React, { Component } from 'react';
import { Link } from 'gatsby';
import Transition from 'react-transition-group/Transition';

import paperCrushed from './paper-crushed-1024.png';
import tintenfleck from './tintenfleck.png';
import fastForwardRoundIcon from './fast-forward-round.png';

class Image extends Component {
  render() {
    const { src, dimension, style, onClick } = this.props;  
    const { width, height } = dimension;
    return (
      <div
        style={style || {}}
        onClick={onClick}
      >
        <img
          width={width}
          height={height}
          src={src}
        /> 
      </div>
    );
  }
}

class Text extends Component {
  render() {
    const duration = 2000;
    const transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
    };
    const style = {
      color: '#555555',
      fontSize: '0.5em',
      transition: `all ${duration}ms ease-in-out`,
    };
    const { children, keyframeProps, hidden } = this.props; 
    return (
      <Transition in={!hidden} appear={true} timeout={0}>
        {
          state => (
            <div 
              style={{ 
                ...style, 
                ...transitionStyles[state]
              }}
            >
              { children}
            </div>
          )
        }
      </Transition>
    );
  }
}

const scene = {
  createItems: scene => [
    {
      id: 'blankPage',
      type: 'component',
      element: <Image src={paperCrushed} />,
    },
    {
      id: 'tintenfleck',
      type: 'component',
      element: <Image src={tintenfleck} />,
    },
    {
      id: 'skipper',
      type: 'component',
      element: <Image 
        src={fastForwardRoundIcon}
        style={{ cursor: 'pointer' }}
        onClick={() => scene.setProgress(36000)}
      />
    },
    {
      id: 'line1One',
      type: 'component',
      element: <Text>Ideas grow in iterations ... </Text>
    },
    {
      id: 'line1Two',
      type: 'component',
      element: (
        <Text> Ideas grow in iterations, they need dedication ... </Text>
      ),
    },
    {
      id: 'line1Three',
      type: 'component',
      element: (
        <Text>Ideas grow in iterations, need dedication and eventually a nurturing team.</Text>
      ),
    },
    {
      id: 'line2One',
      type: 'component',
      element: (
        <Text>Beauty lies in those that drive products ...</Text>
      ),
    },
    {
      id: 'line2Two',
      type: 'component',
      element: (
        <Text>Beauty lies in products that enable people to shape and create.</Text>
      ),
    },
    {
      id: 'line3One',
      type: 'component',
      element: (
        <Text>Technology is an instrument ...</Text>
      ),
    },
    {
      id: 'line3Two',
      type: 'component',
      element: (
        <Text>Technology is an instrument that serves this purpose.</Text>
      ),
    },
    {
      id: 'line3Three',
      type: 'component',
      element: (
        <Text>Technology is an instrument that - when played well - serves this purpose.</Text>
      ),
    },
    {
      id: 'aboutme',
      type: 'component',
      element: (
        <Text> <Link to="/5#mix">personal</Link> and <Link to="/6">professional</Link> selection</Text>
      ),
    },
  ],
  scenes: [
    {
      name: 'one',
      durationInMsec: 36000,
      keyframes: {
        blankPage: [
          {
            progress: 0,
            position: {
              x: 0,
              y: '15%',
            },
            dimension: {
              width: '100%',
              height: '65%'
            },
          },
        ],
        line1One: [ 
          {
            progress: 0,
            position: {
              x: '35%',
              y: '25%',
            }
          },
          {
            progress: 3000,
            position: {
              x: '34.5%',
              y: '25%',
            }
          },
          {
            progress: 3001,
            hidden: true
          }
        ],
        line1Two: [ 
          {
            progress: 0,
            position: {
              x: '30%',
              y: '27%',
            },
            hidden: true
          },
          {
            progress: 3002,
            position: {
              x: '30%',
              y: '27%',
            },
          },
          {
            progress: 4000,
            position: {
              x: '29.8%',
              y: '27%',
            },
          },
          {
            progress: 8001,
            position: {
              x: '29.8%',
              y: '27%',
            },
            hidden: true
          }
        ],
        line1Three: [ 
          {
            progress: 0,
            position: {
              x: '10%',
              y: '35%',
            },
            hidden: true
          },
          {
            progress: 8002,
            position: {
              x: '10%',
              y: '35%',
            },
          },
          {
            progress: 10000,
            position: {
              x: '9.9%',
              y: '35%',
            },
          },
        ],
        line2One: [ 
          {
            progress: 0,
            position: {
              x: '10%',
              y: '45%',
            },
            hidden: true
          },
          {
            progress: 12000,
            position: {
              x: '10%',
              y: '45%',
            },
          },
          {
            progress: 16000,
            position: {
              x: '10.5%',
              y: '45%',
            },
            hidden: true
          }
        ],
        line2Two: [ 
          {
            progress: 0,
            position: {
              x: '55%',
              y: '45%',
            },
            hidden: true
          },
          {
            progress: 17001,
            position: {
              x: '10.5%',
              y: '45%',
            },
          },
          {
            progress: 20000,
            position: {
              x: '10.8%',
              y: '45%',
            },
          }
        ],
        line3One: [
          {
            progress: 0,
            hidden: true
          },
          {
            progress: 21000,
            position: {
              x: '10%',
              y: '55%'
            }
          },
          {
            progress: 25000,
            position: {
              x: '10.1%',
              y: '55%'
            },
            hidden: true
          }
        ],
        line3Two: [
          {
            progress: 0,
            hidden: true
          },
          {
            progress: 26000,
            position: {
              x: '10.3%',
              y: '55%'
            }
          },
          {
            progress: 29000,
            position: {
              x: '10.1%',
              y: '55%'
            },
            hidden: true
          },
        ],
        line3Three: [
          {
            progress: 0,
            hidden: true
          },
          {
            progress: 30000,
            position: {
              x: '10.3%',
              y: '55%'
            }
          },
        ],
        aboutme: [
          {
            progress: 0,
            hidden: true 
          },
          {
            progress: 34000,
            position: {
              x: '50%',
              y: '70%'
            }
          }
        ],
        tintenfleck: [
          {
            progress: 0,
            hidden: true
          },
          {
            progress: 36000,
            position: {
              x: '90%',
              y: '73%'
            },
            dimension: {
              width: 20,
              height: 20
            }
          }
        ],
        skipper: [
          {
            progress: 0,
            hidden: true,
          },
          {
            progress: 10,
            hidden: false,
            position: {
              x: '90%',
              y: '73%'
            },
            dimension: {
              width: 30,
              height: 30
            }
          },
          {
            progress: 35000,
            position: {
              x: '90%',
              y: '73%'
            },
            dimension: {
              width: 30,
              height: 30
            },
            hidden: true
          },
        ]
      },
    },
  ],
};

export default scene;
