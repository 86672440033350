import React, { Component } from 'react';

export default class Water extends Component {
  
  iterate = () => {
    const { population, updatePopulation, gridSize, getCellAt } = this.props;
    const particles = population('particle')
      .filter(p =>
        p.x > 0 &&
        p.x < gridSize.width &&
        p.y > 0 &&
        p.y < gridSize.height
      );
    const updatedParticles = particles.map(particle => {
      const newAge = particle.age + 1;
      const ageBoost = (newAge % 20 === 0) * Math.random() * 1.2;
      const updatedPosition = {
        ...particle, 
        age: newAge,
        x: particle.x + particle.ax,
        y: particle.y + particle.ay,
        ax: Math.round(particle.ax + ageBoost),
        ay: Math.round(particle.ay + ageBoost)
      };
      const cell = getCellAt(updatedPosition.x, updatedPosition.y);
      const updatedParticle = (cell && cell.type === 'reflector')  ?
        {
          ...particle,
          ...updatedPosition,
          ay: cell.direction === 'horizontal' ? -particle.ay : particle.ay,
          ax: cell.direction === 'vertical' ? -particle.ax : particle.ax,
        } : {
            ...particle,
            ...updatedPosition,
        }
      return updatedParticle;
    });
    updatePopulation(updatedParticles, 'particle');
  }

  render() {
    return (
      <div />
    );
  }
}
