import React, { Component } from 'react';

import { ProgressBar, Step } from 'react-step-progress-bar';

import sortDownIcon from './sort-down.png';

import 'react-step-progress-bar/styles.css';

export default class Progress extends Component {
  state = {
    keyframes: {},
    allKeyframes: []
  };

  static getDerivedStateFromProps(props, state) {
    const keyframes = props.keyframes;
    if (state.keyframes != keyframes) {
      const allKeyframes = Object.keys(keyframes).reduce(
        (kfs, itemId) => kfs.concat(keyframes[itemId].map(kf => ({ ...kf, itemId }))),
        []
      );
      return { keyframes, allKeyframes };
    }
    return null;
  }

  render() {
    const { position: progressInPercent, onPositionChange, duration: durationInMsec } = this.props;
    const { allKeyframes } = this.state;
    return (
      <div
        ref={root => this.root = root}
        onClick={e => {
          const rect = e.target.getBoundingClientRect();
          const x = e.clientX - rect.left;
          const relativePosition = Math.min(x / this.root.getBoundingClientRect().width, 1);
          onPositionChange(relativePosition);
        }}
      >
        <ProgressBar
          percent={progressInPercent * 100}
          filledBackground="linear-gradient(to right, #fffb72, #b34f12)"
          stepPositions={allKeyframes.map(kf => durationInMsec ? kf.progress / durationInMsec * 100 : 0)}
        >
          {
            allKeyframes.map((kf, idx) => (
              <Step
                key={idx}
                transition="scale"
              >
                {({ accomplished }) => (
                   <div
                    style={{ marginTop: -30, paddingLeft: 0 }}
                    onClick={ev => {
                      onPositionChange(Math.max(0, kf.progress / durationInMsec));
                      ev.stopPropagation();
                    }}
                  >
                    <img
                      style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                      width="20"
                      src={sortDownIcon}
                    />
                  </div>
                )}
              </Step>
            ))
          }
        </ProgressBar>
      </div>
    );
  }
}
