import React, { Component } from 'react';
import { Link } from 'gatsby';

export default class Menu extends Component {
  render() {
    const { onNavigate, menuEntries } = this.props;
    return (
      <div className="menu">
        <ul>
          {
            menuEntries.map((entry, idx) =>
              <li
                key={entry || idx}
                className="menu-item"
              >
                <Link
                  to={`/${idx + 2}`}
                  onClick={() => onNavigate()}
                >
                  {entry}
                </Link>
              </li>
            )
          }
        </ul>
      </div>
    );
  }
}
