import React, { Component } from 'react';
import { split } from 'eol';

const data = '4368726973746f7068204261737475636b0a546175726f6767656e657220537472617373652033340a3130353839204265726c696e0a6d61696c40636261737475636b2e64650a3033303931393039333936';

export default class Imprint extends Component {
  ex = data => {
    let s = '';
    for (let i = 0; i < data.length; i+=2) {
      s += String.fromCharCode(parseInt(data.substr(i, 2), 16));
    }
    return s.split('\n');
  }

  render() {
    return (
      <div id="imprint">
        <h1>Imprint</h1>
        <p> Angaben gemäß § 5 TMG </p>
        <div>
          <canvas 
            height="90"
            ref={canvas => {
              const lines = this.ex(data); 
              const ctx = canvas && canvas.getContext('2d');
              if (ctx) {
                ctx.font = '14px Courier';
                lines.forEach((line, idx) => ctx.fillText(line, 100, idx * 20 + 10, 1000));
              }
            }} 
          />
        </div>
        <p> Vertreten durch:  Christoph Bastuck </p>
        <h1>Credits</h1>
        <p>
            Background image with coffee stain from <a href="http://www.free-photo-gallery.org/photos/coffee-stain/" target="blank">Roger Karlsson</a>
        </p>
        <p>
            Player icons from <a href="https://icons8.com/" target="blank">icons8</a>
        </p>
      </div>
    );   
  }
}
